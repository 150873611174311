.cogito-container{
    padding: 20px;
    margin-top: 15%;
    border:2px solid #D1D1D1;
    border-radius: 16px;
}
.cogito-text1{
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
}
.cogito-text2{
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
}
.cogito-text3{
    font-family: Manrope!important;
    font-style: normal;
    font-weight: 600!important;
    font-size: 14px!important;
}
.cogito-text4{
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
}
.cogito-color2{
    color: #10ca73!important;
}
.cogito-color3{
    color: #D1D1D1;
}

.cogito-border1{
    border-top:2px solid #EFEFEF;
}
.cogito-background-color1{
    background: #359DB6;
}
.cogito-mt1{
    margin-top:20px;
}
.cogito-mt2{
    margin-top:30px;
}
.cogito-mt3{
    margin-top:10%;
}
.cogito-mt4{
    margin-top:30%;
}
.cogito-pb1{
    padding-bottom:30px;
}
.cogito-ml1{
    margin-left:8px;
}
.cogito-ml2{
    margin-left:12px;
}
/* .MuiInputBase-input {
    padding: 15px 6px 7px!important;
}  
.MuiInput-underline:before {
    content: "" !important;
    transition: none!important;
    border-bottom: none!important;
}
.MuiInput-underline:after {
    border-bottom: none!important;
}*/
/* .MuiFormHelperText-root.Mui-error {
    background: #fff;
    margin-left: 0;
    margin-right: 0;
} */
.d-flex{
    display:flex;
}
.align-items-baseline{
    align-items: baseline;
}
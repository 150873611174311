#pdf-content table {
  background-color: #fff;
  color: #000;
}
#pdf-content th {
  background-color: #fff;
  color: #000;
}
#pdf-content td {
  background-color: #fff;
  color: #000;
}

#pdf-content ::placeholder {
  font-size: 20px;
  color: #999;
  /* vertical-align: middle; */
}

/* Toolbox.css */
.toolbox {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toolbox h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #333;
}

.toolbox-item {
  margin-bottom: 15px;
  width: 250px;
}

.toolbox-item label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.toolbox-item input[type="color"] {
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.color-display {
  width: 50px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-top: 5px;
  display: inline-block;
}

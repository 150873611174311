.carousel {
  max-width: 800px;
  margin: 0 auto;
}

.carousel .slide img {
  max-height: 400px;
  object-fit: contain;
}

.image-container {
  position: relative;
  display: inline-block;
}

.add-image-button {
  display: block;
  margin: 20px auto;
}
